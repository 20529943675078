import React from "react";
import { ThreeDots } from "react-loader-spinner";

type Props = {
  data: any[];
  isLoading: boolean;
  subtitle: string;
  title: string;
};

const DomainBacklinksCategoriesTable: React.FC<Props> = ({ data, isLoading, subtitle, title }) => {
  return (
    <div className="col-12 col-md-8">
      <div className="d-flex justify-content-between pt-5">
        <h3 className="col-12 col-md-8 font-weight-bold">{title}</h3>
        <button className="btn btn-primary btn-vinci" type="button">
          Eksportuj
        </button>
      </div>
      <p className="text-light">{subtitle}</p>
      <hr />
      <div className="card heading mb-2 sh-10 sh-md-4">
        <div className="card-body pt-0 pb-0 h-100">
          <div className="row g-0 h-100 align-content-center">
            <div className="col-12 col-md-11 d-flex align-items-center text-muted text-small mb-2 mb-md-0">
              <a className="body-link text-truncate" role="button">
                Nazwa kategorii
              </a>
            </div>
            <div className="col-4 col-md-1 d-flex align-items-center text-muted text-small mb-1 mb-md-0">
              <a className="body-link text-truncate" role="button">
                Rating
              </a>
            </div>
          </div>
        </div>
      </div>
      {!isLoading &&
        data?.map((category: any) => {
          return (
            <div className="card mb-2 sh-10 sh-md-8" key={category.category_name}>
              <div className="card-body pt-0 pb-0 h-100">
                <div className="row g-0 h-100 align-content-center">
                  <div className="col-12 col-md-11 d-flex align-items-center mb-2 mb-md-0">
                    <a className="body-link text-truncate" href="#">
                      {category.category_name}
                    </a>
                  </div>
                  <div className="col-4 col-md-1 d-flex align-items-center text-muted text-medium mb-1 mb-md-0">
                    <span className="text-medium text-dark">{category.rating}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <ThreeDots
        ariaLabel="three-dots-loading"
        color="#4fa94d"
        height="80"
        radius="9"
        visible={isLoading}
        width="80"
        wrapperStyle={{ justifyContent: "center" }}
      />
    </div>
  );
};

export default DomainBacklinksCategoriesTable;
