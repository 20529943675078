import { object, string, ref } from "yup";

export default object({
  email: string().email("Niepoprawna wartość").required("Pole jest wymagane"),
  name: string().required("Pole jest wymagane"),
  password: string()
    .required("Pole jest wymagane")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$/, {
      message: "Hasło musi zawierać minimum 6 znaków, jedną cyfrę, jedną małą i jedną dużą literę",
    }),
  passwordConfirm: string()
    .required("Pole jest wymagane")
    .oneOf([ref("password"), null], "Podane hasła różnią się od siebie"),
}).required();
