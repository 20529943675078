import React, { useRef } from "react";

import SearchIcon from "src/assets/images/search.svg";

type Props = {
  onSearch: (domain: string) => void;
};

const SearchInput: React.FC<Props> = ({ onSearch }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="search-input mb-3 position-relative">
      <span>Audytuj</span>
      <input className="pe-7 ps-7" name="search" placeholder="www..." ref={inputRef} type="text" />
      <img
        alt=""
        className="position-absolute t-3 e-4"
        onClick={() => onSearch(inputRef.current.value)}
        role="presentation"
        src={SearchIcon}
      />
    </div>
  );
};

export default SearchInput;
