import {
  useLazyGetDomainBacklinksCategoriesQuery,
  useLazyGetDomainCategoriesQuery,
  useLazyGetDomainRatingQuery,
  useLazyGetMetricsHistoryQuery,
  useLazyGetMetricsQuery,
} from "./cockpitApi";

export const useCockpit = () => {
  const [getDomainRating, { data: domainRatingResults, isFetching: domainRatingLoading }] =
    useLazyGetDomainRatingQuery();
  const [getMetricsHistory, { data: metricsHistoryResults, isFetching: metricsHistoryLoading }] =
    useLazyGetMetricsHistoryQuery();
  const [getDomainCategories, { data: categoriesResults, isFetching: domainCategoriesLoading }] =
    useLazyGetDomainCategoriesQuery();
  const [
    getDomainBacklinksCategories,
    { data: domainBacklinksCategoriesResults, isFetching: domainBacklinksCategoriesLoading },
  ] = useLazyGetDomainBacklinksCategoriesQuery();
  const [getMetrics, { data: metricsResults, isFetching: metricsLoading }] = useLazyGetMetricsQuery();

  return {
    domainBacklinksCategoriesData: domainBacklinksCategoriesResults?.categories,
    domainBacklinksCategoriesLoading,
    domainCategoriesData: categoriesResults?.categories,
    domainCategoriesLoading,
    domainRatingData: domainRatingResults?.domainRating,
    domainRatingLoading,
    handleGetDomainBacklinksCategories: async (domain: string) => {
      await getDomainBacklinksCategories({ domain });
    },
    handleGetDomainCategories: async (domain: string) => {
      await getDomainCategories({ domain });
    },
    handleGetDomainRating: async (target: string) => {
      await getDomainRating({ date: "2023-04-20", target });
      return true;
    },
    handleGetMetrics: async (target: string) => {
      const d = new Date();
      const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
      await getMetrics({ date, target });
    },
    handleGetMetricsHistory: async (target: string) => {
      const d = new Date();
      const dateFrom = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
      const dateTo = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
      await getMetricsHistory({ dateFrom, dateTo, target });
    },
    metricsData: metricsResults?.metrics,
    metricsHistoryData: metricsHistoryResults?.metricsHistory,
    metricsHistoryLoading,
    metricsLoading,
  };
};
