import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IResetPasswordRequest } from "src/common/ts/resetPassword.interface";
import AppRoutesEnum from "src/common/ts/enums";

import { useResetPasswordMutation } from "./resetPasswordApi";

export const useResetPassword = () => {
  const navigate = useNavigate();
  const [resetPassword, { data, error, isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (data) {
      toast.success("Pomyślnie zresetowano hasło");
      navigate(AppRoutesEnum.Login);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) toast.error("Wystąpił błąd");
  }, [error]);

  return {
    error,
    handleResetPassword: async (resetPasswordRequestData: IResetPasswordRequest) => {
      await resetPassword(resetPasswordRequestData);
    },
    isLoading,
  };
};
