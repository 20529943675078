import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "src/app/apiConfig";

export const cockpitApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    getDomainBacklinksCategories: build.query<any, any>({
      providesTags: ["Cockpit"],
      query: (query) => ({
        params: query,
        url: `integrations/semrush/getDomainBacklinksCategories`,
      }),
    }),
    getDomainCategories: build.query<any, any>({
      providesTags: ["Cockpit"],
      query: (query) => ({
        params: query,
        url: `integrations/senuto/getDomainCategories`,
      }),
    }),
    getDomainRating: build.query<any, any>({
      providesTags: ["Cockpit"],
      query: (query) => ({
        params: query,
        url: `integrations/ahrefs/getDomainRating`,
      }),
    }),
    getMetrics: build.query<any, any>({
      providesTags: ["Cockpit"],
      query: (query) => ({
        params: query,
        url: `integrations/ahrefs/getMetrics`,
      }),
    }),
    getMetricsHistory: build.query<any, any>({
      providesTags: ["Cockpit"],
      query: (query) => ({
        params: query,
        url: `integrations/ahrefs/getMetricsHistory`,
      }),
    }),
  }),
  keepUnusedDataFor: 1,
  reducerPath: "cockpitApi",
  tagTypes: ["Cockpit"],
});

export const {
  useLazyGetDomainBacklinksCategoriesQuery,
  useLazyGetDomainCategoriesQuery,
  useLazyGetDomainRatingQuery,
  useLazyGetMetricsQuery,
  useLazyGetMetricsHistoryQuery,
} = cockpitApi;
