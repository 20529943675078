import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";

import { useIsAuthQuery } from "./features/login/loginApi";
import AppRoutesEnum from "./common/ts/enums";

const App = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const navigate = useNavigate();

  useIsAuthQuery();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(AppRoutesEnum.Cockpit);
    }
  }, [location, navigate]);

  return (
    <>
      <div>{outlet}</div>
    </>
  );
};

export default App;
