import React from "react";
import { get } from "lodash";

import Footer from "src/components/ui/Footer";
import Header from "src/components/ui/Header";
import CockpitHeader from "src/components/ui/CockpitHeader";
import DomainCategoriesTable from "src/components/ui/DomainCategoriesTable";
import DomainBacklinksCategoriesTable from "src/components/ui/DomainBacklinksCategoriesTable";

import { useCockpit } from "./cockpit.hooks";

const Cockpit = () => {
  const {
    domainBacklinksCategoriesLoading,
    domainBacklinksCategoriesData,
    domainCategoriesData,
    domainCategoriesLoading,
    domainRatingData,
    domainRatingLoading,
    handleGetDomainBacklinksCategories,
    handleGetDomainCategories,
    handleGetDomainRating,
    handleGetMetrics,
    handleGetMetricsHistory,
    metricsData,
    metricsLoading,
    metricsHistoryData,
    metricsHistoryLoading,
  } = useCockpit();

  const handleDomainAudit = (domain: string) => {
    handleGetDomainCategories(domain);
    handleGetDomainRating(domain);
    handleGetMetricsHistory(domain);
    handleGetDomainBacklinksCategories(domain);
    handleGetMetrics(domain);
  };

  return (
    <div className="container p-0 h-100 col-12 col-md-10 position-relative">
      <Header onDomainAudit={(domain: string) => handleDomainAudit(domain)} />
      <div className="container p-0 h-100 col-12 position-relative">
        <div className="row g-0">
          <div className="outer-container rounded-lg h-100 d-flex">
            <aside className="sidebar col-3">
              <div className="side-menu d-flex flex-column my-4">
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_podsumowanie.svg" />
                  Podsumowanie
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_analizaruchu.svg" />
                  Analiza ruchu
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_tresci.svg" />
                  Analiza treści
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_linki.svg" />
                  Analiza linków
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_widocznosc.svg" />
                  Monitoring widoczności
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_frazy.svg" />
                  Monitoring fraz kluczowych
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_kampanie.svg" />
                  Planowanie kampanii
                </a>
                <a className="w-100 px-3 py-2 my-2 text-white" href="#">
                  <img alt="" className="ms-2 me-4" src="gfx/menu_dzialania.svg" />
                  Planowanie działań
                </a>
              </div>
              <img alt="" className="mx-auto d-block my-3" src="gfx/logo-outline.svg" />
            </aside>
            <div className="main-content position-relative w-100 bg-foreground rounded-xl-top-start rounded-xl-bottom-start d-flex">
              <div className="container">
                <div className="row g-3 px-3">
                  <CockpitHeader
                    isLoading={domainRatingLoading}
                    tabs={[
                      { name: "Rating domeny", value: get(domainRatingData, "domain_rating", "-") },
                      { name: "Ranking domeny", value: get(domainRatingData, "ahrefs_rank", "-") },
                    ]}
                    title="Rating i ranking domeny"
                  />
                  <CockpitHeader
                    isLoading={metricsHistoryLoading}
                    tabs={[
                      { name: "Ruch organiczny", value: get(metricsHistoryData, "[0].org_traffic", "-") },
                      { name: "Ruch płatny", value: get(metricsHistoryData, "[0].paid_traffic", "-") },
                    ]}
                    title="Ruch z wyszukiwarek (ostatni miesiąc)"
                  />
                  <CockpitHeader
                    isLoading={metricsLoading}
                    tabs={[
                      { name: "Słowa organiczne", value: get(metricsData, "org_keywords", "-") },
                      { name: "Słowa płatne", value: get(metricsData, "paid_keywords", "-") },
                    ]}
                    title="Słowa kluczowe domeny"
                  />
                  <DomainCategoriesTable
                    data={domainCategoriesData}
                    isLoading={domainCategoriesLoading}
                    subtitle="Widoczność kategorii domeny"
                    title="Kategorie domeny"
                  />
                  <DomainBacklinksCategoriesTable
                    data={domainBacklinksCategoriesData}
                    isLoading={domainBacklinksCategoriesLoading}
                    subtitle="Rating linków zwrotnych domeny"
                    title="Linki zwrotne"
                  />
                  <div className="col-12 col-md-4 d-flex">
                    <div className="col-12 rounded-lg bg-gradient-2 mb-2 d-flex align-items-center justify-content-center">
                      <h3 className="text-medium text-white">Lorem ipsum</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cockpit;
