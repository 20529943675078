import React from "react";

import LogoOutline from "src/assets/images/logo-outline.svg";

const Footer = () => {
  return (
    <div className="row g-0">
      <div className="copyright mt-5 mx-auto text-center d-flex flex-column justify-content-center">
        <img alt="" src={LogoOutline} />
        <span className="text-medium text-white mt-1">© 2023</span>
      </div>
    </div>
  );
};

export default Footer;
