import { configureStore } from "@reduxjs/toolkit";
import { cockpitApi } from "src/features/cockpit/cockpitApi";
import { forgotPasswordApi } from "src/features/forgotPassword/forgotPasswordApi";

import { loginApi } from "src/features/login/loginApi";
import { registerApi } from "src/features/register/registerApi";
import { resetPasswordApi } from "src/features/resetPassword/resetPasswordApi";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(registerApi.middleware)
      .concat(forgotPasswordApi.middleware)
      .concat(resetPasswordApi.middleware)
      .concat(cockpitApi.middleware),
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [cockpitApi.reducerPath]: cockpitApi.reducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
