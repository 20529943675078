import React, { useState } from "react";

import { ThreeDots } from "react-loader-spinner";

type Props = {
  isLoading: boolean;
  tabs: { name: string; value: string }[];
  title: string;
};

const CockpitHeader: React.FC<Props> = ({ isLoading, tabs, title }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const style = { alignItems: "center", display: "flex", justifyContent: "center" };

  return (
    <div className="col-12 col-md-4 d-flex">
      <div className="col-12 tile-transparent rounded-lg" style={isLoading ? style : {}}>
        <ThreeDots
          ariaLabel="three-dots-loading"
          color="#4fa94d"
          height="80"
          radius="9"
          visible={isLoading}
          width="80"
          wrapperStyle={{ justifyContent: "center" }}
        />
        {!isLoading && (
          <>
            <h3 className="text-medium text-center">{title}</h3>
            {tabs.map(({ value }, i) => {
              return (
                activeTabIndex === i && (
                  <div className="value text-dark position-relative" key={value}>
                    {value}
                  </div>
                )
              );
            })}

            <div className="tile-footer">
              {tabs.map(({ name }, i) => {
                return (
                  <div className="tab" key={name} onClick={() => setActiveTabIndex(i)}>
                    <a
                      className={`${
                        activeTabIndex === i ? "font-weight-bold" : "text-small"
                      } text-dark font-weight-bold`}
                      role="button"
                    >
                      {name}
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CockpitHeader;
