import React from "react";

import LogoTopIcon from "src/assets/images/logo_top.svg";
import SettingsIcon from "src/assets/images/settings.svg";
import LogoutIcon from "src/assets/images/logout.svg";
import { logout } from "src/utils/logout";

import SearchInput from "../form/SearchInput";

type Props = {
  onDomainAudit: (domain: string) => void;
};

const Header: React.FC<Props> = ({ onDomainAudit }) => {
  return (
    <div className="row g-0">
      <header>
        <nav className="d-flex justify-content-between align-items-end py-4">
          <img alt="Vinci - seo system" src={LogoTopIcon} />
          <div className="nav-right d-flex">
            <ul className="nav-menu gap-5 p-0 m-0 d-flex me-5">
              <li>
                <a className="text-white nav-link" href="#">
                  Kokpit
                </a>
              </li>
              <li>
                <a className="text-white nav-link" href="#">
                  Intranet
                </a>
              </li>
              <li>
                <a className="text-white nav-link" href="#">
                  Eksport danych
                </a>
              </li>
            </ul>
            <div className="navprofile d-flex gap-3 text-white align-items-end">
              <span className="p-1 ms-5">Witaj</span>
              <a role="button" tabIndex={0}>
                <img alt="" className="pb-1" src={SettingsIcon} />
              </a>
              <a onClick={logout} role="button" tabIndex={0}>
                <img alt="" className="pb-1" src={LogoutIcon} />
              </a>
            </div>
          </div>
        </nav>
        <div className="col-12 d-flex justify-content-between align-items-center">
          <h1 className="col-7 text-white">
            Wszystko czego potrzebujesz w <span className="text-gradient">pozycjonowaniu</span>
          </h1>
          <SearchInput onSearch={(domain: string) => onDomainAudit(domain)} />
        </div>
      </header>
    </div>
  );
};

export default Header;
