import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Login from "./features/login/Login";

import AppRoutesEnum from "./common/ts/enums";
import App from "./App";
import Cockpit from "./features/cockpit/Cockpit";
import Register from "./features/register/Register";
import ResetPassword from "./features/resetPassword/ResetPassword";
import ForgotPassword from "./features/forgotPassword/ForgotPassword";

function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={AppRoutesEnum.Login} />
        <Route element={<Register />} path={AppRoutesEnum.Register} />
        <Route element={<ForgotPassword />} path={AppRoutesEnum.ForgotPassword} />
        <Route element={<ResetPassword />} path={AppRoutesEnum.ResetPassword} />
        <Route element={<App />} path="/">
          <Route element={<Cockpit />} path={AppRoutesEnum.Cockpit} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Root;
