import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { customBaseQuery } from "src/app/apiConfig";
import { IRegisterRequest } from "src/common/ts/register.interface";

export const registerApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    register: build.mutation<any, IRegisterRequest>({
      query: (data) => ({
        body: data,
        method: "POST",
        url: "register",
      }),
    }),
  }),
  reducerPath: "registerApi",
  tagTypes: ["Register"],
});

export const { useRegisterMutation } = registerApi;
