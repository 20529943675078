import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";

import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import { IForgotPasswordRequest } from "src/common/ts/forgotPassword.interface";
import AuthWrapper, { NavigateToEnum } from "src/components/ui/AuthWrapper";

import MailIcon from "src/assets/images/Mail.svg";

import ForgotPasswordSchema from "./forgotPassword.schema";
import { useForgotPassword } from "./forgotPassword.hooks";

const ForgotPassword = () => {
  const { handleForgotPassword, error } = useForgotPassword();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitted },
  } = useForm<IForgotPasswordRequest>({
    resolver: yupResolver(ForgotPasswordSchema),
    reValidateMode: "onChange",
  });

  return (
    <AuthWrapper
      navigateTo={NavigateToEnum.Login}
      subtitle="Lorem ipsum dolor sit amet."
      title="Poznałeś już nasze nowości?"
    >
      <div className="pt-5">
        <div className="tooltip-end-bottom text-center" id="loginForm">
          <div className="mb-3 filled form-group tooltip-end-top">
            <AppInput
              error={formErrors.email?.message || get(error, "data.errors.email")}
              icon={MailIcon}
              id="email"
              inputType={InputTypes.TEXT}
              isSubmited={isSubmitted}
              placeholder="Adres e-mail"
              register={register}
            />
          </div>
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="btn-xl btn-primary mb-5"
            onClick={handleSubmit(handleForgotPassword)}
            title="Przypomnij hasło"
          />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
