import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "src/app/apiConfig";
import { IResetPasswordRequest } from "src/common/ts/resetPassword.interface";

export const resetPasswordApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    resetPassword: build.mutation<any, IResetPasswordRequest>({
      query: (data) => ({
        body: data,
        method: "POST",
        url: `resetPassword`,
      }),
    }),
  }),
  reducerPath: "resetPasswordApi",
  tagTypes: ["ResetPassword"],
});

export const { useResetPasswordMutation } = resetPasswordApi;
