import React from "react";

export enum ButtonColorTypes {
  PRIMARY = "btn-primary",
}

type Props = {
  color: ButtonColorTypes;
  title: string;
  customClass?: string;
  onClick?: () => void;
};

const AppButton: React.FC<Props> = ({ color, title, customClass, onClick }) => {
  return (
    <button className={`btn btn-vinci ${color} ${customClass || ""}`} onClick={onClick} type="button">
      {title}
    </button>
  );
};

export default AppButton;
