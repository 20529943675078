import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AppRoutesEnum from "src/common/ts/enums";
import { IRegisterRequest } from "src/common/ts/register.interface";

import { useRegisterMutation } from "./registerApi";

export const useRegister = () => {
  const navigate = useNavigate();
  const [register, { error, data, isLoading }] = useRegisterMutation();

  useEffect(() => {
    if (data) {
      toast.success("Zarejestrowano");
      navigate(AppRoutesEnum.Login);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      toast.error("Wystąpił błąd");
    }
  }, [error]);

  return {
    error,
    handleRegister: async (registerRequestData: IRegisterRequest) => {
      await register(registerRequestData);
    },
    isLoading,
  };
};
